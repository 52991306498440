<template>
  <div class="introb1">
    <div class="introb1__wrapper">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1 h1--white">Modern French dining</h1>
        <p class="p p--white introb1__p">
          Das Hauptaugenmerk unserer Küche liegt auf französischem Handwerk, das
          mit feinem, asiatischem Gaumen abgeschmeckt wird – dafür sorgen allein
          schon zum einen unsere Geschäftsführer und Brüder Thi und Vu Nguyen,
          deren Wurzeln in Vietnam liegen, sowie unser fantastisches Küchenteam.
        </p>

        <div class="introb1__logo">
          <TheLogo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLogo from "@/components/ui/logo/TheLogo.vue";
export default {
  components: { TheLogo },
};
</script>

<style lang="scss"></style>
