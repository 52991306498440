<template>
  <div class="gallerietwo">
    <div class="gallerietwo__wrapper">
      <div class="gallerietwo__content">
        <div class="gallerietwo__leftblock">
          <div
            class="
              gallerieMotion
              gallerietwo__item gallerietwo__item--u1_1 gallerietwo__item--big
            "
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/01_ueberuns/21_06_07_THI_0892.jpg'"
              :imgposition="'center 50%'"
            />
          </div>

          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u1_2"
            data-motion="'hallo'"
          >
            <UeberTextInfo :h1="true">
              <template v-slot:h1>Cuisine</template>
              <template v-slot:p>
                Mit viel Leidenschaft präsentieren Tore Johansen(Chef de Cuisine) und Jacob Weis (Sous Chef), was die moderne französische Küche ausmacht: Aus saisonalen Zutaten wird in aromatischen Gerichten das Beste herausgeholt, die raffinierten Kombinationen mit asiatischen Einflüssen liefern besondere Geschmacksmomente – ohne Schnickschnack, aber stets besonders.
              </template>
            </UeberTextInfo>
          </div>
          <div
            class="
              gallerieMotion
              gallerietwo__item gallerietwo__item--u1_3 gallerietwo__item--small
            "
            data-motion="'hallo'"
          >
            <SwiperGallery :dataObject="gallerie_1" />
          </div>
        </div>
        <div class="gallerietwo__rightblock">
          <div
            class="
              gallerieMotion
              gallerietwo__item gallerietwo__item--u1_4 gallerietwo__item--small
            "
            data-motion="'hallo'"
          >
            <SwiperGallery :dataObject="gallerie_2" />
          </div>
          <div
            class="
              gallerieMotion
              gallerietwo__item gallerietwo__item--u1_5 gallerietwo__item--big
            "
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/01_ueberuns/21_06_07_THI_0454.jpg'"
              :imgposition="'center 50%'"
            />
          </div>
          <div
            class="
              gallerieMotion
              gallerietwo__item gallerietwo__item--u1_6 gallerietwo__item
            "
            data-motion="'hallo'"
          >
            <UeberTextInfo :h1="false">
              <template v-slot:p>
                Unsere Küchenchefs präsentieren in regelmäßigen Abständen neue
                Gerichte auf höchstem Niveau. Ob Lunch am Mittag oder Dinner am
                Abend: gekocht wird mit Leib, Seele und den köstlichsten Zutaten
                der Saison.</template
              >
            </UeberTextInfo>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperGallery from "@/components/common/swiper/SwiperGallery.vue";
import GalleryPic from "@/components/common/gallery/GalleryPic.vue";
import getPathFunction from "@/utilities/composition/getPathFunction";
import UeberTextInfo from "./UeberTextInfo.vue";
export default {
  components: { GalleryPic, SwiperGallery, UeberTextInfo },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const gallerie_1 = [
      "assets/img/01_ueberuns/gallerie_1/5.jpg",
      "assets/img/01_ueberuns/gallerie_1/1.jpg",
      "assets/img/01_ueberuns/gallerie_1/2.jpg",
      "assets/img/01_ueberuns/gallerie_1/3.jpg",
      "assets/img/01_ueberuns/gallerie_1/4.jpg",
    ];
    const gallerie_2 = [
      "assets/img/01_ueberuns/gallerie_2/1.jpg",
      "assets/img/01_ueberuns/gallerie_2/2.jpg",
      "assets/img/01_ueberuns/gallerie_2/3.jpg",
      "assets/img/01_ueberuns/gallerie_2/4.jpg",
    ];
    return { setBackGroundPath, gallerie_1, gallerie_2 };
  },
};
</script>

<style lang="scss">
.gallerietwo__item {
  &--u1_1 {
    width: 100%;
    flex: 1 1 auto;
    order: 1;

    @media screen and (min-width: $medium) {
      order: 1;
    }
  }
  &--u1_2 {
    width: 100%;
    flex: 0 1 auto;
    order: 2;

    @media screen and (min-width: $medium) {
      order: 2;
    }
  }
  &--u1_3 {
    width: 100%;
    flex: 0 1 auto;
    order: 3;
    @media screen and (min-width: $medium) {
      order: 3;
    }
  }
  &--u1_4 {
    width: 100%;
    flex: 0 1 auto;
    order: 3;
    padding-bottom: 0;
    @media screen and (min-width: $medium) {
      order: 1;
      padding-bottom: unset;
    }
  }
  &--u1_5 {
    width: 100%;
    flex: 1 1 auto;
    order: 2;
    @media screen and (min-width: $medium) {
      order: 2;
    }
  }
  &--u1_6 {
    width: 100%;
    flex: 0 1 auto;
    order: 1;
    @media screen and (min-width: $medium) {
      order: 3;
    }
  }

  &--hides {
    display: none;
    @media screen and (min-width: $medium) {
      display: block;
    }
  }

  &--hidel {
    @media screen and (min-width: $medium) {
      display: none;
    }
  }
}
</style>
