<template>
  <div class="gallerietwo">
    <div class="gallerietwo__wrapper">
      <div class="gallerietwo__content">
        <div class="gallerietwo__leftblock">
          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u2_1"
            data-motion="'hallo'"
          >
            <UeberTextInfo :h1="true">
              <template v-slot:h1>Getränke</template>
              <template v-slot:p>
                Quelle surprise: Wenn wir in der Brasserie Thi von Getränken reden, meinen wir eigentlich Wein – und das reichlich. Dafür sorgt unsere Restaurantleiterin und soon to be Sommelière Merjem Music.</template
              >
            </UeberTextInfo>
          </div>
          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u2_2 gallerietwo__item--big"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/01_ueberuns/21_06_07_THI_0091.jpg'"
              :imgposition="'center 50%'"
            />
          </div>

          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u2_3 gallerietwo__item--small"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/01_ueberuns/21_06_07_THI_0299.jpg'"
              :imgposition="'center 50%'"
            />
          </div>
        </div>
        <div class="gallerietwo__rightblock">
          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u2_4 gallerietwo__item--small"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/01_ueberuns/21_06_07_THI_0800.jpg'"
              :imgposition="'center 70%'"
            />
          </div>

          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u2_5 gallerietwo__item"
            data-motion="'hallo'"
          >
            <UeberTextInfo :h1="false">
              <template v-slot:p>
                Wir wissen immer, welcher Wein gerade am besten passt. Ob das
                gemütliche Feierabend-Glas mit Freunden oder die Weinbegleitung
                zum Menü: Für jede Situation haben wir den richtigen – und
                oftmals sehr seltenen – Tropfen im Repertoire. <br /><br />
                Santé!</template
              >
            </UeberTextInfo>
          </div>
          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u2_6 gallerietwo__item--big"
            data-motion="'hallo'"
          >
            <SwiperGallery :dataObject="gallerie_3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperGallery from "@/components/common/swiper/SwiperGallery.vue";
import GalleryPic from "@/components/common/gallery/GalleryPic.vue";
import getPathFunction from "@/utilities/composition/getPathFunction";
import UeberTextInfo from "./UeberTextInfo.vue";
export default {
  components: { GalleryPic, SwiperGallery, UeberTextInfo },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const gallerie_3 = [
      "assets/img/01_ueberuns/gallerie_3/1.jpg",
      "assets/img/01_ueberuns/gallerie_3/2.jpg",
      "assets/img/01_ueberuns/gallerie_3/3.jpg",
      "assets/img/01_ueberuns/gallerie_3/4.jpg",
      "assets/img/01_ueberuns/gallerie_3/5.jpg",
      "assets/img/01_ueberuns/gallerie_3/6.jpg",
    ];
    return { setBackGroundPath, gallerie_3 };
  },
};
</script>

<style lang="scss">
.gallerietwo__item {
  &--u2_1 {
    width: 100%;
    flex: 1 1 auto;
    order: 2;
    @media screen and (min-width: $medium) {
      order: 1;
    }
  }
  &--u2_2 {
    width: 100%;
    flex: 0 1 auto;
    order: 1;
    @media screen and (min-width: $medium) {
      order: 2;
    }
  }
  &--u2_3 {
    width: 100%;
    flex: 0 1 auto;
    order: 3;
    @media screen and (min-width: $medium) {
      order: 3;
    }
  }
  &--u2_4 {
    width: 100%;
    flex: 0 1 auto;
    order: 2;
    padding-bottom: 0;
    @media screen and (min-width: $medium) {
      order: 1;
      padding-bottom: unset;
    }
  }
  &--u2_5 {
    width: 100%;
    flex: 0 1 auto;
    order: 1;
    @media screen and (min-width: $medium) {
      order: 2;
    }
  }
  &--u2_6 {
    width: 100%;
    flex: 1 1 auto;
    order: 1;
    @media screen and (min-width: $medium) {
      order: 3;
    }
  }
}
</style>
